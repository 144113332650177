/**
 * MS-营销中心接口
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/marketing`; //服务前缀

//查询窗口优惠列表 - 马鹏浩
export const windowDiscountFindList = () => {
  return http.get(`${prefix}/window/discount/find/list`);
};

//整单折扣计算优惠金额-许家炜
export const windowDiscountDiscountedPrice = data => {
  return http.post(`${prefix}/window/discount/discounted/price`, data);
};

//计算优惠金额-许家炜
export const windowActivityDiscountedPrice = data => {
  return http.post(`${prefix}/window/activity/discounted/price`, data);
};

//窗口查询可用优惠券-许家炜
export const windowCouponActivityList = data => {
  return http.post(`${prefix}/window/coupon/activity/list`, data);
};

// 根据活动id查询活动详情 - 马鹏浩
export const getMarketingActivityById = data => {
  return http.get(`${prefix}/get/marketing_activity/by/id`, data);
};

// 判断窗口折扣是否可用-许家炜
export const windowIsBooleanDiscount = data => {
  return http.get(`${prefix}/window/isBoolean/discount`, data);
};

/**
 *营销活动信息
 */
// 【market026】根据会员号和状态码获取优惠券集合 - 马鹏浩
export function marketingCouponList(data) {
  return http.get(`${prefix}/marketing/coupon/list`, data);
}

//【market112】窗口兑换优惠券
export function windowRedeemCoupons(data) {
  return http.post(`${prefix}/window/redeem/coupons`, data);
}

/**################################ 新旧接口分界线  ##########################################*/
/**
 *营销活动信息接口
 */

//  查询优惠券记录
export const findMemberCouponsInfo = data => {
  return http.get(`${prefix}/find/member/coupons/info`, data);
};
//  查询优惠券信息-许家炜
export const listMarketingCouponInfo = () => {
  return http.get(`${prefix}/list/marketing_coupon/info`);
};
// 活动类型列表接口-许家炜
export const listMarketingActivityCategory = () => {
  return http.get(`${prefix}/list/marketing_activity_category`);
};
// 创建营销活动(新增营销活动)-许家炜
export const saveInfoMarketingActivity = data => {
  return http.add(`${prefix}/save/info/marketing_activity`, data);
};
// 营销活动列表-许家炜
export const listMarketingActivityInfo = data => {
  return http.get(`${prefix}/list/marketing_activity/info`, data);
};
// 暂停活动-许家炜
export const updateStatusMarketingActivity = data => {
  return http.put(`${prefix}/update_status/marketing_activity`, data);
};
// 修改营销活动-许家炜
export const updateInfoMarketingActivity = data => {
  return http.put(`${prefix}/update/info/marketing_activity`, data);
};

// 根据ID查询优惠信息 修改回显-许家炜
export const modifyEchoMarketingActivityById = data => {
  return http.get(`${prefix}/modify_echo/marketing_activity/by/id`, data);
};

//订单折扣-营销活动列表展示(满减、窗口活动)-许家炜
export const WindowActivityList = data => {
  return http.post(`${prefix}/window/activity/list`, data);
};

/**
 * 全名营销
 */

// 更新全民营销配置开关-胡汉杰
export const saleSettingSwitch = data => {
  return http.put(`${prefix}/sale/setting/switch`, data);
};
// 获取全民营销配置-胡汉杰
export const saleSetting = () => {
  return http.get(`${prefix}/sale/setting`);
};
// 营销产品添加-胡汉杰
export const saleProducts = data => {
  return http.post(`${prefix}/sale/products`, data);
};
// 全民营销配置-胡汉杰
export const putSaleSetting = data => {
  return http.put(`${prefix}/sale/setting`, data);
};
// 营销产品更新佣金比例-胡汉杰
export const saleCommissionProduct = data => {
  return http.put(`${prefix}/sale/commission/product`, data);
};
// 营销产品批量修改佣金比例-胡汉杰
export const saleCommissionProducts = data => {
  return http.put(`${prefix}/sale/commission/products`, data);
};
// 营销产品批量更新状态-胡汉杰
export const saleStatusProducts = data => {
  return http.put(`${prefix}/sale/status/products`, data);
};
// 营销产品设置海报-胡汉杰
export const saleProductShareimg = data => {
  return http.put(`${prefix}/sale/product/shareimg`, data);
};
// 营销产品营销概况-胡汉杰
export const saleProductStat = data => {
  return http.get(`${prefix}/sale/product/stat`, data);
};
// 营销产品更改营销状态-胡汉杰
export const saleStatusProduct = data => {
  return http.put(`${prefix}/sale/status/product`, data);
};
// 营销产品海报浏览记录-胡汉杰
export const saleProductRecordPage = () => {
  return http.get(`${prefix}/sale/product/record/page`);
};
// 营销产品获取详情-胡汉杰
export const saleProduct = () => {
  return http.get(`${prefix}/sale/product`);
};
// 营销员获取分页列表-胡汉杰
export const saleMemberPage = data => {
  return http.get(`${prefix}/sale/member/page`, data);
};
// 营销员审核、清退-胡汉杰
export const saleMemberStatus = data => {
  return http.put(`${prefix}/sale/member/status`, data);
};
// 营销员详情、业绩概况-胡汉杰
export const saleMember = data => {
  return http.get(`${prefix}/sale/member`, data);
};
// 营销员提现记录-胡汉杰
export const saleMemberAccountWithdrawPage = data => {
  return http.get(`${prefix}/sale/member/account/withdraw/page`, data);
};
// 营销员提现审核-胡汉杰
export const saleMemberAccountWithdrawStatus = data => {
  return http.put(`${prefix}/sale/member/account/withdraw/status`, data);
};
// 营销员提现批量审核-胡汉杰
export const saleMemberAccountWithdrawStatusBatch = data => {
  return http.put(`${prefix}/sale/member/account/withdraw/status/batch`, data);
};

/**
 * ota平台管理
 */

// 添加ota平台 -刘资涌
export const saveOtaPlatform = data => {
  return http.add(`${prefix}/save/ota/platform`, data);
};
// 获取ota平台详情
export const oneOtaPlatform = data => {
  return http.get(`${prefix}/one/ota/platform`, data);
};
// 更新ota平台
export const updateOtaPlatform = data => {
  return http.put(`${prefix}/update/ota/platform`, data);
};
// 分页显示ota平台
export const pageOtaPlatform = data => {
  return http.get(`${prefix}/page/ota/platform`, data);
};
// 下拉框change事件 异步请求 -刘资涌
export const changeBoxOta = data => {
  return http.get(`${prefix}/change/box/ota`, data);
};

/**
 * ota平台管理可销售产品（自测ok）
 */

// 添加可销售的产品 -刘资涌
export const saveOtaProduct = data => {
  return http.add(`${prefix}/save/ota/product`, data);
};
// 查看可销售产品的详情 -刘资涌
export const oneOtaProduct = data => {
  return http.get(`${prefix}/one/ota/product`, data);
};
// 分页查询可销售产品 -刘资涌
export const pageOtaProduct = data => {
  return http.get(`${prefix}/page/ota/product`, data);
};
// 更新可销售产品 -刘资涌
export const updateOtaProduct = data => {
  return http.put(`${prefix}/update/ota/product`, data);
};
//删除OTA可销售产品（取消引用） -刘资涌
export const statusOtaProduct = data => {
  return http.delete(`${prefix}/status/ota/product`, data);
};
// 获取产品价格日历-胡汉杰
export const otaPlatformProductSpecialPriceYearMonth = data => {
  return http.get(
    `${prefix}/ota/platform/product/special_price/year_month`,
    data
  );
};
// 配置产品价格日历-胡汉杰
export const otaPlatformProductSpecialPrice = data => {
  return http.post(`${prefix}/ota/platform/product/special_price`, data);
};
// 还原产品价格日历-胡汉杰
export const otaPlatformProductSpecialPriceDefault = data => {
  return http.put(`${prefix}/ota/platform/product/special_price/default`, data);
};
