<template>
  <main>
    <!-- 头部开始 -->
    <a-form layout="inline" class="mb-2x">
      <a-form-item label="产品名称">
        <a-input
          placeholder="请输入产品名称"
          allowClear
          v-model="search.productName"
        ></a-input>
      </a-form-item>
      <a-form-item label="商户名称">
        <a-input
          placeholder="请输入商户名称"
          allowClear
          v-model="search.merchantName"
        ></a-input>
      </a-form-item>
      <a-form-item label="产品类型">
        <a-select
          placeholder="请选择"
          style="width:150px"
          v-model="search.productType"
        >
          <a-select-option label="全部" value="" key="0">全部</a-select-option>
          <a-select-option
            :value="item.cid1"
            v-for="item in selectOptions2"
            :key="item.cid1"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchBtn">
          查询
        </a-button>
      </a-form-item>
    </a-form>
    <!--    @change="searchBtn"-->
    <a-table
      :loading="tableLoading"
      :columns="columns"
      :data-source="tableList"
      :scroll="{ y: 400 }"
      :pagination="pagination"
      @change="paginationChange"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
    >
      <template slot="sellingPrice" slot-scope="scope">
        <span>{{ scope.sellingPrice | money }}</span>
      </template>
    </a-table>
  </main>
</template>

<script>
import { productFrontCouponapplyProduct } from "../../../../api/search";
const columns = [
  {
    key: "name",
    title: "产品名称",
    // ellipsis: false,  //设置true为...
    width: 50,
    dataIndex: "name"
  },
  {
    key: "merchantName",
    title: "所属商户",
    // ellipsis: false,  //设置true为...
    width: 80,
    dataIndex: "merchantName"
  },
  {
    key: "productNo",
    title: "产品编号",
    // ellipsis: false,  //设置true为...
    width: 50,
    dataIndex: "productNo"
  },
  {
    key: "cid1",
    title: "产品类型",
    // ellipsis: false,  //设置true为...
    width: 100,
    dataIndex: "cid1"
  },
  {
    key: "categoryName",
    title: "自定义类型",
    // ellipsis: false,  //设置true为...
    width: 100,
    dataIndex: "categoryName"
  },
  {
    key: "sellingPrice",
    title: "销售价格",
    // ellipsis: false,  //设置true为...
    width: 50,
    scopedSlots: {
      customRender: "sellingPrice"
    }
  }
];
export default {
  name: "LookApplyProducts",
  props: {
    // 商户id数组
    merchantIds: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      columns,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      search: {
        productName: "",
        merchantName: "",
        productType: ""
      },
      tableList: [],
      tableLoading: false,
      selectOptions2: [
        { name: "门票", cid1: "1365211690130337793" },
        { name: "综合套票", cid1: "1365211690470076417" },
        { name: "项目套餐", cid1: "1365211691942277121" },
        { name: "门票资产套餐", cid1: "1365211695276748801" },
        { name: "零售商品", cid1: "1365211692399456258" },
        { name: "酒店", cid1: "1365211693653553153" }
      ]
    };
  },
  watch: {
    merchantIds: function() {
      this.getDataList();
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.tableLoading = true;
      this.tableList = [];
      productFrontCouponapplyProduct({
        ids: this.merchantIds.join(","),
        name: this.search.productName,
        merchantName: this.search.merchantName,
        cid1: this.search.productType,
        size: this.pagination.pageSize,
        page: this.pagination.current
      })
        .then(res => {
          if (res.records !== null && res.records.length) {
            this.pagination.total = res.total;
            this.tableList = res.records;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.tableLoading = false;
        });
    },
    paginationChange(val) {
      this.pagination.current = val.current;
      this.pagination.pageSize = val.pageSize;
      this.getDataList();
    },
    searchBtn() {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getDataList();
    }
  }
};
</script>

<style scoped></style>
