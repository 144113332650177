<template>
  <div>
    <main class="member-detail flex-col flex-start wrapper" v-show="memberId">
      <!-- 头部 -->
      <panel-head title="会员详情 " @close="closeWin"></panel-head>
      <!-- 主体 -->
      <section class="main mt-2x">
        <!-- 主体 重构-->
        <a-row type="flex" justify="space-between" :gutter="[8, 8]">
          <a-col :xxl="12" :lg="12" :sm="24">
            <div class="bg-white ph-2x pb-2x height-100">
              <div class="flex-between align-center">
                <h2 class="title-mid flex-shrink">会员信息</h2>
                <div class="flex-end flex-wrap">
                  <a-button
                    type="primary"
                    ghost
                    class="mr-2x mt-x"
                    @click="changeMember"
                  >
                    更换会员
                  </a-button>
                  <a-button-group class="flex-end align-center mt-x">
                    <!--                    <a-button type="primary" size="small"-->
                    <!--                    >会员操作</a-button>-->
                    <a-dropdown :trigger="['click']" placement="bottomRight">
                      <a-button
                        type="primary"
                        plain
                        ghost
                        @click="e => e.preventDefault()"
                      >
                        会员操作<a-icon type="down" />
                      </a-button>
                      <a-menu slot="overlay">
                        <a-menu-item
                          v-for="(item, index) in menuList"
                          :key="item.id"
                          class="mh-x"
                          :disabled="
                            memberInfoOne.whetherTemporaryMember === 1 &&
                              (item.id === 14 || item.id === 1)
                          "
                          :class="{
                            'bd-b dashed': index < menuList.length - 1
                          }"
                          @click="menuClick(item.id)"
                        >
                          <p class="font-l pv-x">
                            {{
                              item.id === 14
                                ? memberInfoOne.cardNumber
                                  ? "修改实体卡号"
                                  : item.name
                                : item.name
                            }}
                          </p>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </a-button-group>
                </div>
              </div>
              <a-row>
                <a-col :xl="12" :xxl="8">
                  <p class="pb-1x">
                    <span>姓名: </span>
                    <span class="gray">{{ memberInfoOne.name || "-" }}</span>
                  </p>
                  <p class="pb-1x">
                    <span>性别: </span>
                    <span class="gray">{{
                      !memberInfoOne.sex
                        ? "未知"
                        : memberInfoOne.sex === 1
                        ? "女"
                        : "男"
                    }}</span>
                  </p>
                  <p class="pb-1x">
                    <span>身份证: </span>
                    <span class="gray">{{
                      memberInfoOne.idCardNumber || "-"
                    }}</span>
                  </p>
                </a-col>
                <a-col :xl="12" :xxl="8">
                  <p class="pb-1x">
                    <span>手机号: </span>
                    <span class="gray">{{ memberInfoOne.mobile || "-" }}</span>
                  </p>
                  <p class="pb-1x">
                    <span>生日: </span>
                    <span class="gray" v-if="memberInfoOne.birthday">{{
                      memberInfoOne.birthday | date
                    }}</span>
                    <template v-else>-</template>
                  </p>
                  <p class="pb-1x">
                    <span>会员来源: </span>
                    <span class="gray">{{
                      memberInfoOne.sourceText || "-"
                    }}</span>
                  </p>
                </a-col>
                <a-col :xl="12" :xxl="8">
                  <p class="pb-1x">
                    <span>成为会员: </span>
                    <span class="gray">{{
                      memberInfoOne.createTime | date
                    }}</span>
                  </p>
                  <p class="pb-1x">
                    <span>最后消费: </span>
                    <span v-if="memberInfoOne.lastConsumeTime" class="gray">{{
                      memberInfoOne.lastConsumeTime | date
                    }}</span>
                    <template v-else>-</template>
                  </p>
                  <p
                    class="pb-1x"
                    v-if="memberInfoOne.whetherTemporaryMember === 0"
                  >
                    <span>实体卡号: </span>
                    <span class="gray">{{
                      memberInfoOne.cardNumber || "-"
                    }}</span>
                  </p>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :xxl="12" :lg="12" :sm="24">
            <div class="bg-white ph-2x pb-2x height-100">
              <div class="flex-between">
                <h2 class="title-mid">会员凭证</h2>
              </div>
              <a-row :gutter="[0, 8]">
                <a-col :lg="12" :xl="8" :xxl="6">
                  <span>手机号: </span>
                  <span class="gray">{{ memberInfoOne.mobile || "-" }}</span>
                </a-col>
                <a-col :lg="12" :xl="8" :xxl="6">
                  <span>卡腕带: </span>
                  <span class="gray">{{ memberInfoOne.wristBand || "-" }}</span>
                </a-col>
                <a-col :lg="12" :xl="8" :xxl="6">
                  <span>证件号: </span>
                  <span class="gray">{{ memberInfoOne.idNo || "-" }}</span>
                </a-col>
                <a-col :lg="12" :xl="8" :xxl="6">
                  <span>票号: </span>
                  <span class="gray">{{ memberInfoOne.ticketNo || "-" }}</span>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <span>人像: </span>
                  <a-avatar
                    class="flex-shrink mr-2x"
                    shape="square"
                    :size="50"
                    :src="memberInfoOne.facePersonUrl"
                  />
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
        <div class="bg-white main-member-padding mt-2x">
          <!--会员资产-->
          <AlterationMemberPage
            :member-info-one="memberInfoOne"
          ></AlterationMemberPage>
          <!--产品列表-->
          <ProductListPage
            ref="productList"
            :member-info-one="memberInfoOne"
          ></ProductListPage>
          <!--优惠券列表-->
          <h2 class="title-mid normal">优惠券</h2>
          <!--优惠券-->
          <div>
            <a-tabs
              default-active-key="1"
              type="card"
              tabPosition="top"
              @change="tabChange"
            >
              <a-tab-pane key="1" tab="可用" />
              <a-tab-pane key="2" tab="已过期" />
              <a-tab-pane key="3" tab="已使用" />
              <a-tab-pane key="4" tab="明细" />
            </a-tabs>
            <!--            优惠券明细-->
            <div v-if="tabActive === '4'">
              <CouponDetail></CouponDetail>
            </div>
            <!--            优惠券列表-->
            <div v-else>
              <template v-if="couponsList.length">
                <a-row :gutter="[24, 24]">
                  <a-col
                    :xxl="6"
                    :xl="8"
                    :lg="12"
                    v-for="(item, index) in couponsList"
                    :key="index"
                  >
                    <div class="coupon-wrapper">
                      <header
                        class="coupon-amount"
                        :class="
                          tabActive === '1'
                            ? 'amount-top-bg'
                            : 'amount-top-bg-disabled'
                        "
                      >
                        <div
                          :class="
                            tabActive === '1'
                              ? 'amount-top-img'
                              : 'amount-top-img-disabled'
                          "
                        >
                          <b class="font-xxl">
                            {{ item.discountedPriceText }}
                          </b>
                          <p class="mt-1x">{{ item.useConditionsText }}</p>
                        </div>
                        <div class="circle"></div>
                        <div class="circle left"></div>
                      </header>

                      <div class="p-2x">
                        <a-row class="mb-1x">
                          <a-col :span="6" class="text">券名称：</a-col>
                          <a-col :span="18" class="gray">{{
                            item.giftCouponNames
                          }}</a-col>
                        </a-row>
                        <a-row class="mb-1x">
                          <a-col :span="6" class="text">券编号：</a-col>
                          <a-col :span="18" class="gray">{{
                            item.giftCouponId
                          }}</a-col>
                        </a-row>
                        <a-row class="mb-1x">
                          <a-col :span="6" class="text">有效期：</a-col>
                          <a-col :span="18" class="gray"
                            >{{ item.activityStartDate | date }} ~
                            {{ item.activityEndDate | date }}
                          </a-col>
                        </a-row>
                        <a-row class="mb-1x">
                          <a-col :span="6" class="text">限产品：</a-col>
                          <a-col :span="18" class="gray text-2">{{
                            item.productText
                          }}</a-col>
                        </a-row>
                        <div class="text-c pointer blue">
                          <span @click="getMarketingActivity(item.id)"
                            >查看全部适用产品</span
                          >
                          <a-icon type="right" class="mt-x" />
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
                <div
                  v-if="couponsListCopy.length > 4"
                  class="text-c mt-2x blue pointer"
                  @click="showMore"
                >
                  {{ isIconDown ? "查看更多" : "收起" }}
                  <a-icon v-show="isIconDown" type="down" />
                  <a-icon v-show="!isIconDown" type="up" />
                </div>
              </template>
              <!--            空值-->
              <a-empty v-else />
            </div>
          </div>
          <!-- 会员数据-->
          <h2 class="title-mid normal">会员数据</h2>
          <div class="pb-3x">
            <a-row :gutter="[16, 8]">
              <a-col :xl="6" :lg="12">
                <div class="height-100 flex-col flex-between member-item img-3">
                  <div>
                    <h2 class="font-l bold">消费</h2>
                    <div class="flex-start">
                      <p class="line-height">总金额：</p>
                      <h2 class="font-xxxxl">
                        {{ memberInfoOne.orderTotalPrice | money }}
                      </h2>
                    </div>
                  </div>
                  <div class="flex-between">
                    <p>总次数：{{ memberInfoOne.orderTradeCount }}</p>
                    <a-button
                      @click="consumptionClick"
                      shape="round"
                      size="small"
                      >明细</a-button
                    >
                  </div>
                </div>
              </a-col>
              <a-col :xl="6" :lg="12">
                <div class="height-100 flex-col flex-between member-item img-3">
                  <div>
                    <h2 class="font-l bold">核销</h2>
                    <div class="flex-start">
                      <p class="line-height">总金额：</p>
                      <h2 class="font-xxxxl">
                        {{ memberInfoOne.writeOffTotalPrice | money }}
                      </h2>
                    </div>
                  </div>
                  <div class="flex-between">
                    <p>总次数：{{ memberInfoOne.writeOffTotalCount }}</p>
                    <!--                    <a-button-->
                    <!--                      @click="verificationClick"-->
                    <!--                      shape="round"-->
                    <!--                      size="small"-->
                    <!--                      >明细</a-button-->
                    <!--                    >-->
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <!--      主体 重构 end-->
      </section>
      <!-- 修改支付密码弹窗 -->
      <a-modal
        v-model="passwordVisible"
        title="设置支付密码"
        @ok="changePassword"
        @cancel="cancelChangePassword"
      >
        <a-form-model
          ref="changePass"
          :model="form"
          :rules="rules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          label-align="left"
        >
          <!--          whetherTemporaryMember= 1 为临时会员-->
          <a-form-model-item
            v-if="memberInfoOne.whetherTemporaryMember === 0"
            label="会员手机号"
            :colon="false"
          >
            <div class="flex-between">
              <p>{{ memberInfoOne.mobile }}</p>
              <p v-if="!isClick" class="pointer blue" @click="getValidateCode">
                发送验证码
              </p>
              <p v-else class="blue">
                <a-icon type="clock-circle" />
                {{ time }} 秒
              </p>
            </div>
          </a-form-model-item>
          <a-form-model-item
            v-if="memberInfoOne.whetherTemporaryMember === 0"
            label="验证码 "
            prop="msgCode"
            :colon="false"
          >
            <a-input
              v-model="form.msgCode"
              autocomplete="off"
              allow-clear
              placeholder="请输入短信验证码"
            />
          </a-form-model-item>
          <a-form-model-item
            v-else
            label="原密码"
            prop="oldCode"
            :rules="[
              {
                required: true,
                message: '请输入原密码',
                trigger: 'blur'
              }
            ]"
            :colon="false"
          >
            <a-input
              v-model="form.oldCode"
              autocomplete="off"
              allow-clear
              placeholder="请输入原密码"
            />
          </a-form-model-item>
          <a-form-model-item label="新支付密码" prop="password" :colon="false">
            <a-input-password
              v-model="form.password"
              autocomplete="new-password"
              placeholder="请输入6位纯数字支付密码"
              :max-length="6"
              allow-clear
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!--      2022/03-->
      <!-- 适用产品 弹窗 -->
      <a-modal
        v-model="showLookAppProducts"
        title="查看适用产品"
        :footer="null"
        @cancel="lookAppProductsCancel"
        :width="1000"
      >
        <look-apply-products :merchantIds="merchantIds"></look-apply-products>
      </a-modal>
    </main>
    <!--搜索会员-->
    <search-member v-show="!memberId" @close="closeWin"></search-member>
    <!--    修改实体卡号-->
    <a-modal
      v-model="showCardNumberBind"
      title="实体卡绑定"
      @ok="bindCardClick"
    >
      <a-form-item label="实体卡号">
        <IcCardinput
          v-model="cardNumber"
          placeholder="请输入实体卡号"
        ></IcCardinput>
      </a-form-item>
    </a-modal>
    <!--    产品列表-->
    <TablePopup
      title="消费明细"
      :tableData="tableData"
      :tableColumns="consumptionColumns"
      :visible.sync="consumptionVisible"
      :pagination="tablePagination"
      @paginationChange="consumptionPaginationChange"
      @onSearch="consumptionSearch"
    >
      <template slot="action">
        <a-table-column width="180px" title="操作" fixed="right">
          <!--            <div slot="title">-->
          <!--              操作-->
          <!--            </div>-->
          <div slot-scope="row">
            <a-button
              type="link"
              class="ph-x"
              :disabled="!chargeBackList.includes(row.orderStatus)"
              @click="chargeBack(row.id)"
              >退单</a-button
            >
            <a-button
              type="link"
              class="ph-x"
              :disabled="!forceChargeBackList.includes(row.orderStatus)"
              @click="forceChargeBack(row.id)"
              >强制退单</a-button
            >
            <!--            <a-button class="ph-x" type="link" @click="toDetail(row)"-->
            <!--              >详情</a-button-->
            <!--            >-->
          </div>
        </a-table-column>
      </template>
    </TablePopup>
    <!--    核销明细列表-->
    <TablePopup
      title="核销明细"
      :tableData="[]"
      :tableColumns="verificationColumns"
      :visible.sync="verificationVisible"
      :pagination="tablePagination"
      @paginationChange="verificationPaginationChange"
      @onSearch="verificationSearch"
    >
      <template slot="action">
        <a-table-column width="180px" title="操作" fixed="right">
          <div slot-scope="row">
            <a-button
              class="ph-x"
              type="link"
              @click="cancelVerificationClick(row)"
              >反核销</a-button
            >
          </div>
        </a-table-column>
      </template>
    </TablePopup>
    <!-- 退款弹窗 -->
    <refund-public
      title="退整单"
      :refund-show="refundShow"
      :refund-info="refundInfo"
      @success="refundOk"
      @cancel="refundShow = false"
    ></refund-public>
    <!-- 强制退款弹窗 -->
    <force-refund-public
      title="强制退单"
      :refund-show="forceRefundShow"
      @success="forceRefundOk"
      @cancel="forceRefundShow = false"
    ></force-refund-public>
  </div>
</template>

<script>
// 消费明细
const consumptionColumns = [
  {
    key: "createTime",
    title: "下单时间",
    fixed: "left",
    width: 180,
    scopedSlots: { customRender: "date" }
  },
  {
    key: "id",
    title: "总单号",
    width: 180
  },
  {
    key: "ownerFormatTypeInfos",
    title: "总单业态集",
    width: 150,
    ellipsis: true
  },
  {
    key: "salespersonName",
    title: "销售员",
    width: 100
  },
  {
    key: "orderBusinessTypeText",
    title: "业务类型",
    width: 120
  },
  {
    key: "buyChannelsText",
    title: "下单渠道",
    width: 100
  },
  {
    key: "totalAmount",
    title: "应收金额",
    width: 100,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "discountAmount",
    title: "优惠金额",
    width: 120,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "integralDeductionAmount",
    title: "会员积分抵扣",
    width: 150,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "actualAmount",
    title: "实收金额",
    width: 100,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "payWayName",
    title: "支付方式",
    width: 160
  }
];
// 核销明细
const verificationColumns = [
  {
    key: "id",
    title: "单品编号（票号）",
    fixed: "left",
    width: 180
  },
  {
    key: "ownerFormatTypeInfos",
    title: "单品名称",
    width: 120
  },
  {
    key: "ownerMerchants",
    title: "组合产品编号(票号)",
    width: 180
  },
  {
    key: "salespersonName",
    title: "组合产品名称",
    width: 120
  },
  {
    key: "orderBusinessTypeText",
    title: "核销时间",
    width: 180,
    scopedSlots: { customRender: "date" }
  },
  {
    key: "createTime",
    title: "可核销次数",
    width: 160
  },
  {
    key: "actualAmount",
    title: "已核销次数",
    width: 120
  },
  {
    key: "totalRefundAmount",
    title: "核销员",
    width: 120
  },
  {
    key: "outsideCode",
    title: "核销渠道",
    width: 100
  },
  {
    key: "billingStatus",
    title: "核销单号",
    width: 180
  },
  {
    key: "billingTime",
    title: "核销状态",
    width: 160
  }
];
import LookApplyProducts from "./components/LookApplyProducts";
import { marketingCouponList, getMarketingActivityById } from "@/api/marketing"; // MS-会员中心接口
import { orderConsumedetail } from "@/api/search"; // MS-会员中心接口
// 2022/03 end
import PanelHead from "@/components/common/PanelHead";
import { encrypt } from "@/utils/crypt-aes";
import { mapActions, mapGetters } from "vuex";
import {
  orderOneMemberInfo,
  levelOne,
  updatePaymentPassword,
  updatePaymentPasswordTemporary,
  memberBindingEntityCard
} from "@/api/member"; // MS-会员中心接口
import { messageVerifyCode } from "@/api/message"; // MS-信息中心接口
import SearchMember from "./SearchMember.vue";
import IcCardinput from "@/components/hardware/IcCardinput";
import AlterationMemberPage from "./components/AlterationMemberPage";
import ProductListPage from "./components/ProductListPage";
import CouponDetail from "./components/CouponDetail";
import TablePopup from "@/components/member/TablePopup";
import { refundOrderCheck, refundOrder, refundOrderForce } from "@/api/order";
import RefundPublic from "../orderNew/components/RefundPublic.vue";
import ForceRefundPublic from "../orderNew/components/ForceRefundPublic.vue";
export default {
  name: "MemberDetail",
  components: {
    PanelHead,
    SearchMember,
    LookApplyProducts,
    IcCardinput,
    CouponDetail,
    TablePopup,
    RefundPublic,
    ForceRefundPublic,
    AlterationMemberPage,
    ProductListPage
  },
  data() {
    // 自定义校验设置支付密码
    let checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入支付密码"));
      } else if (!/^[0-9]{6}$/.test(value)) {
        callback(new Error("请输入6位纯数字支付密码"));
      } else {
        callback();
      }
    };

    return {
      // 2022-3
      tabActive: "1",
      pagination: {
        // 产品列表table分页配置
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      tableLoading: false, // 产品table加载 loading
      showLookAppProducts: false, //查看适用产品
      merchantIds: [], //商户id数组
      couponsInfoList: [], // 优惠券明细列表
      couponsList: [], // 优惠券
      couponsListCopy: [], // 优惠券缓存
      isIconDown: true,
      // 2022-3
      levelId: 0, // 会员等级id
      levelOne: {}, // 会员等级详情
      memberInfoOne: {}, // 会员详情
      passwordVisible: false, // 是否显示修改密码弹窗
      timer: null, // 验证码定时器
      time: 60, // 验证码倒计时
      couponInfoList: [], // 会员未使用优惠券列表
      menuList: [
        { id: 1, name: "修改会员资料" },
        { id: 2, name: "修改支付密码" },
        { id: 9, name: "资产变更记录" },
        { id: 14, name: "绑定实体卡号" },
        { id: 13, name: "退出会员" }
      ],
      form: {
        msgCode: "",
        password: ""
      },
      rules: {
        msgCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, validator: checkPassword, trigger: "blur" }
        ]
      },
      isClick: false,
      showCardNumberBind: false,
      cardNumber: "", // 会员实体卡号
      // popupType: "", // 弹框类型
      tableData: [],
      tableColumns: [], // 积分明细columns
      searchKey: "", // 搜索关键词
      tablePagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      // 会员数据
      verificationColumns, // 核销columns
      verificationVisible: false, // 核销明细
      verificationPagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      consumptionColumns, // 消费columns
      consumptionVisible: false, // 消费明细
      consumptionPagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      chargeBackList: [3, 9, 16, 18], // 退单按钮可选中状态值
      forceChargeBackList: [17, 18], // 强制退单按钮可选中状态值
      refundShow: false, // 整单退款弹框
      refundInfo: {}, // 整单退款校验信息
      forceRefundShow: false, // 强制退单弹框
      orderId: "" // 订单id
    };
  },
  computed: {
    ...mapGetters({ memberId: "common/memberId" })
  },
  watch: {
    memberId: {
      handler: function() {
        this.getMemberInfoOne();
      }
    }
  },
  created() {
    // 2022/03 end
    this.getMemberInfoOne(); // 根据会员id获取会员详情
    // 设置当前页为支付完成后的跳转页面（会员充值，开通特权用到）
    this.$store.commit("order/setEndOfPayConfig", {
      backUrl: this.$route.path,
      logoutMember: false
    });
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    // 2022/03
    /**
     * 会员资产区域
     */
    // 根据会员id获取会员详情
    getMemberInfoOne() {
      if (this.memberId) {
        orderOneMemberInfo({ memberId: this.memberId })
          .then(res => {
            this.memberInfoOne = res || {};
            this.updateMember(this.memberInfoOne);
            this.$nextTick(() => {
              this.$refs.productList.getOrderMemberProduct(); // 产品列表
            });
          })
          .catch(() => {
            this.updateMember({});
          });
        this.getCouponsList();
      }
    },
    /**
     * 会员数据
     * @param key
     */
    // 会员数据
    consumptionClick() {
      // 消费明细
      this.resetTable();
      this.orderConsumedetailLoad();
    },
    orderConsumedetailLoad() {
      orderConsumedetail({
        memberId: this.memberId,
        orderId: this.searchKey,
        page: this.tablePagination.current,
        size: this.tablePagination.pageSize
      }).then(res => {
        this.consumptionVisible = true;
        this.tableData = res.records;
        this.tablePagination.total = res.total;
      });
    },
    consumptionSearch(val) {
      // 消费弹框搜索
      this.searchKey = val;
      this.tablePagination.current = 1;
      this.orderConsumedetailLoad();
    },
    // 消费明细page变化
    consumptionPaginationChange(val) {
      this.tablePagination.current = val.current;
      this.tablePagination.pageSize = val.pageSize;
      this.orderConsumedetailLoad();
    },
    verificationClick() {
      // 核销明细
      this.resetTable();
      this.verificationVisible = true;
    },
    verificationSearch(val) {
      // 核销弹框搜索
      this.searchKey = val;
      // this.integralRecordLoad();
    },
    // 核销明细page变化
    verificationPaginationChange(val) {
      console.log(val);
      this.tablePagination.current = val.current;
      this.tablePagination.pageSize = val.pageSize;
    },
    // 反核销
    cancelVerificationClick() {},
    // 退单校验
    chargeBack(id) {
      this.orderId = id;
      refundOrderCheck({
        mainOrderId: id
      }).then(res => {
        this.refundShow = true;
        this.refundInfo = res || {};
      });
    },
    // 强制退单
    forceChargeBack(id) {
      this.orderId = id;
      this.forceRefundShow = true;
    },
    // 完成退款
    refundOk(obj) {
      this.refundShow = false;
      refundOrder({
        mainOrderId: this.orderId,
        remarks: obj.remarks
      })
        .then(res => {
          // 消费明细
          this.orderConsumedetailLoad();
          this.$message.success(
            res.refundFailMsg ? res.refundFailMsg : "退单成功！"
          );
        })
        .catch(() => {});
    },
    // 强制完成退款
    forceRefundOk(obj) {
      this.forceRefundShow = false;
      refundOrderForce({
        mainOrderId: this.orderId,
        remarks: obj.remarks
      })
        .then(() => {
          // 消费明细
          this.orderConsumedetailLoad();
          this.$message.success("强制退单成功！");
        })
        .catch(() => {});
    },
    // 详情
    toDetail(row) {
      console.log(row);
    },
    // 弹框表单重置
    resetTable() {
      this.tablePagination = {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showSizeChanger: true,
        showQuickJumper: true
      };
      this.searchKey = "";
      this.tableData = [];
    },
    /**
     * 优惠劵区域
     * @param key
     */
    // tab切换
    tabChange(key) {
      this.tabActive = key;
      if (key !== "4") {
        this.getCouponsList();
      }
    },
    // 根据活动id查询活动详情
    getMarketingActivity(id) {
      this.showLookAppProducts = true;
      getMarketingActivityById({ id }).then(res => {
        this.merchantIds = res.applicableProducts.map(item => {
          return item.products;
        });
      });
    },
    // 获取未使用优惠券列表
    getCouponsList() {
      this.couponsList = [];
      marketingCouponList({
        memberId: this.memberId,
        status: this.tabActive
      }).then(res => {
        this.couponsListCopy = res || [];
        this.isIconDown = true;
        this.couponsList = this.couponsListCopy.slice(0, 4);
      });
    },
    // 显示更多
    showMore() {
      this.isIconDown = !this.isIconDown;
      if (this.isIconDown) this.couponsList = this.couponsListCopy.slice(0, 4);
      else this.couponsList = this.couponsListCopy;
    },
    lookAppProductsCancel() {
      this.$store.commit("common/updateLoading", false);
    },
    ...mapActions("common", ["updateMember"]),
    // 2022/03 end
    closeWin() {
      this.$emit("close");
    },
    // 根据会员等级id查询会员等级
    getLevelOne() {
      levelOne({ id: this.levelId })
        .then(res => {
          this.levelOne = res;
        })
        .catch(() => {});
    },

    // 更换会员
    changeMember() {
      this.searchMember();
    },

    // 去查询会员
    searchMember() {
      this.updateMember({});
    },

    // 菜单点击
    menuClick(id) {
      switch (id) {
        case 1: // 修改会员资料
          this.$router.push({
            path: "/home/member/MemberEdit",
            query: { pageType: "edit" }
          });
          break;
        case 2: // 修改支付密码
          this.passwordVisible = true;
          break;
        case 9: // 资产变更记录
          this.$router.push({ path: "/home/member/AssetChangeRecord" });
          break;
        // case 10: // 积分变更记录
        //   // this.$router.push({ path: "/home/member/ScoreChangeRecord" });
        //   this.resetTable();
        //   this.popupType = 1; // 积分弹框
        //   this.tableTitle = "积分明细";
        //   this.tableColumns = integralColumns;
        //   this.integralRecordLoad();
        //   break;
        // case 11: // 成长变更记录
        //   // this.$router.push({ path: "/home/member/GrowthChangeRecord" });
        //   this.resetTable();
        //   this.popupType = 2; //  成长值变更弹框
        //   this.tableTitle = "成长值";
        //   this.tableColumns = growUpColumns; // 成长值columns
        //   this.integralRecordLoad();
        //   break;
        case 12: // 优惠券记录
          this.$router.push({ path: "/home/member/MemberCoupon" });
          break;
        case 13: // 退出会员
          this.updateMember({});
          //this.$router.push({ path: "/home" });
          break;
        case 14: // 绑定实体卡号
          this.showCardNumberBind = true;
          break;
        default:
          break;
      }
    },
    // 实体卡绑定
    bindCardClick() {
      if (!this.cardNumber) {
        this.$message.warning("请输入实体卡号！");
        return;
      }
      memberBindingEntityCard({
        memberId: this.memberId,
        cardNumber: this.cardNumber
      }).then(() => {
        this.showCardNumberBind = false;
        this.$message.success("操作成功!");
        this.getMemberInfoOne(); // 根据会员id获取会员详情
      });
    },
    // 修改支付密码
    changePassword() {
      this.$refs.changePass.validate(valid => {
        if (valid) {
          // 加密处理，获得加密后的秘钥和密文，whetherTemporaryMember === 0 永久会员 1 临时会员
          let api =
            this.memberInfoOne.whetherTemporaryMember === 0
              ? updatePaymentPassword
              : updatePaymentPasswordTemporary;
          let { key, encryptedWord } = encrypt(this.form.password);
          let obj = {
            id: this.memberId, // 会员id
            paymentPassword: encryptedWord, // 会员修改的支付密码
            passwordKey: key // 标识
          };
          if (this.memberInfoOne.whetherTemporaryMember === 0) {
            // 永久会员
            obj.smsValidCode = this.form.msgCode; // 短信验证码
          } else {
            // 原支付密码，临时会员
            obj.originalPassword = this.form.oldCode;
          }
          api(obj)
            .then(() => {
              this.passwordVisible = false;
              this.getMemberInfoOne();
            })
            .catch(() => {});
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },

    // 取消修改支付密码
    cancelChangePassword() {
      this.clearTimer();
    },

    // 发送短信验证码
    getValidateCode() {
      // 防止点击过快（双击），或定时器一时没这么快执行，又继续点击。
      // 造成的定时器出现的各种问题
      if (this.isClick) {
        return;
      }
      this.isClick = true;

      messageVerifyCode({
        verifyCodeType: "MEMBER_ALTER_PASSWORD", // 验证码类型
        objectPhone: this.memberInfoOne.mobile // 接收的手机号
      })
        .then(() => {
          this.$message.success("发送成功");
          this.setTimer();
        })
        .catch(() => {
          this.clearTimer();
        });
    },

    // 验证码定时器
    setTimer() {
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          this.clearTimer();
        } else {
          this.time--;
        }
      }, 1000);
    },

    // 清除验证码定时器
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.time = 60;
      this.isClick = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.wrapper {
  /*<!--2022/03-->*/
  .font-xxm {
    font-size: 22px;
    font-weight: bold;
  }
  margin-right: 0;
  .height-100 {
    height: 100%;
  }
  .min-width {
    min-width: 90px;
  }
  .main {
    background: transparent;
    .main-member-padding {
      padding: 1px 16px 16px;
    }
  }
  .member-item {
    flex-shrink: 0;
    overflow: hidden;
    /*width: 388px;*/
    border-radius: 16px;
    padding: 16px 16px !important;
    /*box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);*/
    &.img-1 {
      background-image: url("../../../assets/images/menber-img1.png");
      background-size: cover;
    }
    &.img-2 {
      background: #eff5ff;
    }
    &.img-3 {
      background: #f6f6f6;
    }
  }
  .coupon-wrapper {
    border-radius: 5px;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
    /*height: 86px;*/
    /*<!--background: @primary-light2;-->*/
    background: #f8f8f8;
    .coupon-amount {
      border-radius: 5px;
      position: relative;
      text-align: center;
      &.amount-top-bg {
        background: linear-gradient(to right, #ff2a2a, #ff4b82);
      }
      &.amount-top-bg-disabled {
        background: linear-gradient(to right, #c9c9c9, #777777);
      }
      border-bottom: 1px dashed @border;
      color: #fff;
      .amount-top-img {
        padding: 10px 8px;
        background: url("../../../assets/images/coupon-bg-card.png") no-repeat -1px;
        background-size: cover;
      }
      .amount-top-img-disabled {
        padding: 10px 8px;
        background: url("../../../assets/images/coupon-bg-card-disabled.png")
          no-repeat -1px;
        background-size: cover;
      }
      &.disabled {
        background: #eee;
        color: @disabled;
      }
      .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        left: -10px;
        bottom: -10px;
        background: #fff;
        &.left {
          left: unset;
          right: -10px;
        }
      }
    }
    .icon-tip {
      position: absolute;
      right: 16px;
      bottom: 0;
      font-size: 60px;
    }
  }
  /*<!--2022/03 end-->*/
  /*卡片*/
  .cards {
    .item {
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
      width: 388px;
      height: 186px;
      margin: 0 16px 16px 8px;
      border-radius: 16px;
      background-size: cover;
      padding: 16px;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);

      &.level {
        padding: 24px 28px;
        color: #fff;
        text-shadow: 2px 2px 2px #666;
      }

      .update {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;
        cursor: pointer;
        > img {
          position: absolute;
          top: -4px;
          right: -4px;
          width: 80px;
          height: 80px;
          transform: rotateZ(90deg);
        }
        > p {
          position: absolute;
          left: 40px;
          top: 13px;
          font-size: 16px;
          transform: rotateZ(45deg);
        }
      }
    }
  }

  /*优惠券*/
  .coupon {
    margin: 16px;
    border-radius: 5px;
    border: 1px solid @border;
    overflow: hidden;
    .top {
      padding: 12px 16px;
      border-bottom: 1px solid @border;
      // background: @primary-light2;
    }

    .item {
      position: relative;
      flex-shrink: 0;
      width: 372px;
      height: 85px;
      margin: 16px 16px 16px 0;
      border-radius: 5px;
      box-shadow: 1px 0 9px rgba(0, 0, 0, 0.1);
      .title {
        height: 100%;
        padding: 12px 16px;
        background: @primary-light1;
      }
      .money {
        width: 120px;
        height: 100%;
        border-radius: 5px;
        border-left: 1px dashed @border;
        line-height: 85px;
        text-align: center;
        font-size: 24px;
        color: #fff;
        background: @primary;
      }
      .circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: -8px;
        right: 112px;
        background: #fff;
        &.left {
          top: unset;
          bottom: -8px;
        }
      }
    }
  }
  .line-height {
    line-height: 48px;
  }
}
</style>
