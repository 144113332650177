<template>
  <div>
    <div class="flex-end">
      <p class="pb-1x">
        <a-input-search
          v-inputfocus
          allow-clear
          class="search-input"
          placeholder="请输入产品名称"
          v-model="searchKey"
          @search="onSearch"
        >
          <a-button slot="enterButton" icon="search" type="primary" ghost>
            搜索
          </a-button>
        </a-input-search>
      </p>
    </div>
    <a-table
      :columns="columns"
      :data-source="list"
      :scroll="{ x: 800 }"
      :pagination="pagination"
      @change="paginationChange"
      rowKey="id"
    >
      <!--      :pagination="pagination"-->
      <!--      @change="paginationChange"-->
      <template slot="receiveTime" slot-scope="row">
        {{ row.receiveTime | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template slot="activityBeginDate" slot-scope="row">
        {{ row.activityBeginDate | date("YYYY-MM-DD") }} ~
        {{ row.activityEndDate | date("YYYY-MM-DD") }}
      </template>
      <template slot="status" slot-scope="row">
        {{ status[row.status] }}
      </template>
    </a-table>
  </div>
</template>

<script>
import { findMemberCouponsInfo } from "@/api/marketing";
import { mapGetters } from "vuex";
const columns = [
  {
    title: "获取时间",
    key: "receiveTime",
    width: 120,
    scopedSlots: { customRender: "receiveTime" }
  },
  {
    title: "活动名称",
    width: 120,
    key: "activityName",
    dataIndex: "activityName",
    ellipsis: true
  },
  {
    title: "劵码",
    width: 100,
    key: "couponCode",
    dataIndex: "couponCode"
  },
  {
    title: "有效期",
    key: "activityBeginDate",
    width: 120,
    scopedSlots: { customRender: "activityBeginDate" }
  },
  {
    title: "劵状态",
    key: "status",
    width: 80,
    scopedSlots: { customRender: "status" }
  }
];
export default {
  name: "CouponDetail",
  data() {
    return {
      columns,
      page: 1,
      size: 10,
      searchKey: "", // 搜索key
      pagination: {
        // 产品列表table分页配置
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      list: [],
      status: {
        1: "未使用",
        2: "已使用",
        3: "已过期",
        4: "已取消"
      }
    };
  },
  created() {
    this.findMemberCouponsInfoLoad();
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  methods: {
    paginationChange(val) {
      this.pagination.current = val.current;
      this.pagination.pageSize = val.pageSize;
      this.findMemberCouponsInfoLoad();
    },
    findMemberCouponsInfoLoad() {
      findMemberCouponsInfo({
        memberId: this.memberId,
        activityName: this.searchKey,
        size: this.pagination.pageSize,
        page: this.pagination.current
      }).then(res => {
        this.list = res.records;
        this.pagination.total = res.total;
      });
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.findMemberCouponsInfoLoad();
    }
  }
};
</script>

<style scoped></style>
