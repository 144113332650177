<template>
  <div>
    <a-modal
      :title="title"
      @cancel="cancel"
      :visible.sync="refundShow"
      width="600px"
    >
      <a-form-model
        :model="form"
        :rules="rules"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        ref="form"
      >
        <a-form-model-item
          v-if="isVerifyCode"
          prop="validateCode"
          :label="title + '审批验证码'"
        >
          <a-input
            v-model="form.validateCode"
            placeholder="请输入验证码"
            autocomplete="off"
          >
            <a-button
              slot="suffix"
              type="link"
              v-if="time === 60"
              @click="getValidateCode()"
            >
              <span class="font-l">获取验证码</span>
            </a-button>
            <span slot="suffix" class="font-l" v-else>
              <i class="el-icon-time"></i> {{ time }}s
            </span>
          </a-input>
        </a-form-model-item>
        <a-form-model-item :label="title + '备注'">
          <a-input
            type="textarea"
            v-model="form.remarks"
            placeholder="请输入备注"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
      <div slot="footer">
        <a-button type="primary" @click="confirmRefund">{{
          codeType === "FORCED_TO_REFUND" ? "确认退款" : "确认核销"
        }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sendMessageVerify, verifyMessageVerify } from "@/api/message";
import { merchantSettingRefundWrite } from "@/api/merchant";

export default {
  name: "ForceRefundPublic",
  props: {
    codeType: {
      type: String,
      default: "FORCED_TO_REFUND" // FORCED_TO_REFUND强制退款，FORCED_TO_WRITE_OFF 强制核销
    },
    title: {
      type: String,
      default: ""
    },
    refundShow: { type: Boolean, default: false }
  },
  watch: {
    refundShow(v) {
      if (v) {
        this.form.remarks = "";
        this.clearTimerIn();
      }
    }
  },
  data() {
    return {
      rules: {
        validateCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]
      },
      time: 60, // 验证码倒计时
      timerIn: null, // 验证码定时器
      form: {
        remarks: "", // 备注
        validateCode: "" // 验证码
      },
      refundCallNotApproved: "1", // 是否需要短信验证 1需要验证（需要审批），2无需短信验证（不审批）
      writeOffCallNotApproved: "1" // 是否需要短信验证 1需要验证（需要审批），2无需短信验证（不审批）
    };
  },
  beforeDestroy() {
    this.clearTimerIn();
  },
  created() {
    // 判断是否需要短信验证
    this.getMerchantSettingRefundWrite();
  },
  computed: {
    isVerifyCode() {
      if (this.codeType === "FORCED_TO_REFUND") {
        // 强制退款
        return this.refundCallNotApproved == "1";
      } else {
        // 强制核销
        return this.writeOffCallNotApproved == "1";
      }
    }
  },
  methods: {
    getMerchantSettingRefundWrite() {
      merchantSettingRefundWrite().then(res => {
        this.refundCallNotApproved = res.refundCallNotApproved;
        this.writeOffCallNotApproved = res.writeOffCallNotApproved;
      });
    },
    // 获取验证码
    getValidateCode() {
      sendMessageVerify({
        verifyCodeType: this.codeType
      })
        .then(() => {
          this.setTimerIn();
        })
        .catch(() => {
          this.clearTimerIn();
        });
    },
    // 验证码定时器
    setTimerIn() {
      this.time--;
      this.timerIn = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          this.clearTimerIn();
        }
      }, 1000);
    },
    // 去除二维码失效和扫码轮询结果定时器
    clearTimerIn() {
      clearInterval(this.timerIn);
      this.timerIn = null;
      this.time = 60;
    },
    // 关闭
    cancel() {
      this.$emit("cancel");
    },
    //确认退款
    confirmRefund() {
      if (this.isVerifyCode) {
        // 需要短信验证
        this.$refs.form.validate(valid => {
          if (valid) {
            verifyMessageVerify({
              verifyCode: this.form.validateCode,
              verifyCodeType: this.codeType
            }).then(res => {
              if (res) {
                this.$emit("success", this.form);
              } else {
                this.$message.warning("验证码错误");
              }
            });
          }
        });
      } else {
        this.$emit("success", this.form);
      }
    }
  }
};
</script>
