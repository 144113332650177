<!--
  @name:订单退款公共组件
  @author: zhengyao
  @date:2022-1-6
  @attr: {
      refundShow:Boolean 开关
      refundInfo：Object 退款信息
  }
  @methods：{ 
       ok:ok  确认退款
       ok:close 关闭弹窗

  }
-->
<template>
  <div>
    <a-modal
      :title="title"
      @cancel="cancel"
      :visible.sync="refundShow"
      width="600px"
    >
      <div>
        <a-row class="mb-1x">
          <a-col :span="5">订单编号：</a-col>
          <a-col :span="10">{{ refundInfo.orderId }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">订单状态：</a-col>
          <a-col :span="10">{{ refundInfo.orderStatusText }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次退款产品：</a-col>
          <a-col :span="10">{{ refundInfo.productName }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次应退金额：</a-col>
          <a-col :span="10">{{ refundInfo.totalLeftAmount | money }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次退款手续费：</a-col>
          <a-col :span="10">{{
            refundInfo.totalCommissionAmount | money
          }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次退款金额：</a-col>
          <a-col :span="10">{{
            refundInfo.totalRefundableAmount | money
          }}</a-col>
        </a-row>

        <a-row>
          <a-col :span="5">退款备注：</a-col>
          <a-col :span="19"
            ><a-input
              type="textarea"
              v-model="remarks"
              placeholder="请输入退款备注"
            ></a-input
          ></a-col>
        </a-row>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="confirmRefund">确认退款</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "RefundPublic",
  props: {
    title: {
      type: String,
      default: "确认退单"
    },
    refundInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    refundShow: { type: Boolean, default: false }
  },
  data() {
    return {
      orderId: this.$route.query.orderId,
      remarks: ""
    };
  },
  watch: {
    refundInfo() {
      this.remarks = "";
    }
  },
  methods: {
    // 关闭
    cancel() {
      this.$emit("cancel");
    },
    //确认退款
    confirmRefund() {
      let obj = {
        remarks: this.remarks
      };
      this.$emit("success", obj);
    }
  }
};
</script>
