<template>
  <div class="flex-col flex-start wrapper">
    <panel-head title="查询会员" @close="toGo"></panel-head>
    <div class="main mt-2x">
      <find-member></find-member>
      <div class="flex-center">
        <span class="gray">还没会员? </span>
        <span class="blue pointer ml-1x" @click="register">立即注册会员</span>
      </div>
    </div>
  </div>
</template>
<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import FindMember from "../../../components/member/FindMember.vue";

export default {
  name: "SearchMember",
  components: {
    PanelHead,
    FindMember
  },
  data() {
    return {};
  },
  methods: {
    toGo() {
      this.$emit("close");
    },
    // 注册
    register() {
      this.$router.push({
        path: "/home/member/MemberEdit"
      });
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  margin-right: 0;
  .main {
    padding-top: 60px;
  }
}
</style>
