<template>
  <div>
    <h2 class="title-mid normal">产品列表</h2>
    <!-- 分类销售汇总 和 产品销售汇总表格 -->
    <a-table
      :columns="productColumns"
      :data-source="productData"
      :scroll="{ x: 800 }"
      :pagination="pagination"
      @change="paginationChange"
      rowKey="id"
    >
      <template slot="createTime" slot-scope="row">
        {{ row.createTime | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template slot="useDate" slot-scope="row">
        {{ row.useDate | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template slot="firstWriteOffTime" slot-scope="row">
        {{ row.firstWriteOffTime | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template slot="action" slot-scope="row">
        <!--约定 监听是1508733438004285441为兑换券类型，显示明细-->
        <a-button
          v-if="row.productCategoryId === '1508733438004285441'"
          @click="cateGoryDetail(row)"
          type="link"
          class="blue p-0"
          >明细</a-button
        >
      </template>
    </a-table>
    <TablePopup
      title="明细"
      :tableData="coinData"
      :tableColumns="coinColumns"
      :visible.sync="coinVisible"
      :show-search="false"
    />
  </div>
</template>

<script>
import TablePopup from "@/components/member/TablePopup";
import { orderMemberProduct } from "@/api/search"; // MS-会员中心接口
import { memberCoinDetail } from "@/api/member"; // MS-会员中心接口
const productColumns = [
  {
    key: "auxiliaryId",
    title: "产品号(票号)",
    // ellipsis: false,  //设置true为...
    width: 100,
    dataIndex: "auxiliaryId"
  },
  {
    title: "下单时间",
    key: "createTime",
    width: 120,
    scopedSlots: { customRender: "createTime" }
  },
  {
    title: "产品名称",
    width: 120,
    key: "productName",
    dataIndex: "productName",
    ellipsis: true
  },
  {
    title: "购买渠道",
    width: 120,
    key: "buyChannelsText",
    dataIndex: "buyChannelsText"
  },
  {
    title: "预约时间",
    key: "useDate",
    width: 120,
    scopedSlots: { customRender: "useDate" }
  },
  {
    title: "状态",
    key: "statusText",
    width: 80,
    dataIndex: "statusText"
  },
  {
    title: "核销时间",
    key: "firstWriteOffTime",
    width: 120,
    scopedSlots: { customRender: "firstWriteOffTime" }
  },
  {
    title: "核销员",
    key: "lastWriteOffPersonName",
    width: 80,
    dataIndex: "lastWriteOffPersonName"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    key: "",
    width: 80,
    fixed: "right"
  }
];
const coinColumns = [
  {
    title: "充值时间",
    key: "createTime",
    width: 180,
    fixed: "left",
    scopedSlots: { customRender: "date" }
  },
  {
    title: "充值单号",
    key: "totalOrderId",
    width: 180,
    dataIndex: "totalOrderId"
  },
  {
    title: "充值状态",
    key: "payStatusText",
    width: 100,
    dataIndex: "payStatusText"
  },
  {
    title: "支付方式",
    key: "payWayName",
    width: 120,
    dataIndex: "payWayName"
  },
  {
    title: "兑换券名称",
    key: "productName",
    width: 180,
    dataIndex: "productName"
  },
  {
    title: "可用总次数",
    key: "coinCertificateNum",
    width: 120,
    dataIndex: "coinCertificateNum"
  },
  {
    title: "购买者会员id",
    key: "helpMemberId",
    width: 200,
    dataIndex: "helpMemberId"
  },
  {
    title: "购买者手机号",
    key: "helpMemberMobile",
    width: 120,
    dataIndex: "helpMemberMobile"
  },
  {
    title: "有效期",
    key: "validTime",
    width: 120,
    scopedSlots: { customRender: "date" }
  }
];
export default {
  name: "ProductListPage",
  props: {
    memberInfoOne: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    TablePopup
  },
  data() {
    return {
      coinData: [], // 兑换券明细列表
      coinColumns,
      coinVisible: false, // 产品下兑换券明细弹框开关
      productColumns, // 产品列表columns配置
      productData: [], // 产品列表数据
      pagination: {
        // 产品列表table分页配置
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20"],
        showSizeChanger: true,
        showQuickJumper: true
      }
    };
  },
  methods: {
    /**
     * 产品列表区域
     * @param key
     */
    paginationChange(val) {
      this.pagination.current = val.current;
      this.pagination.pageSize = val.pageSize;
      this.getOrderMemberProduct();
    },
    getOrderMemberProduct() {
      orderMemberProduct({
        keyword:
          this.memberInfoOne.mobile ||
          this.memberInfoOne.idCardNumber ||
          this.memberInfoOne.wristBand ||
          this.memberId,
        size: this.pagination.pageSize,
        page: this.pagination.current
      }).then(res => {
        this.pagination.total = res.total;
        this.productData = res.records;
      });
    },
    // 如果是兑换券，显示明细
    cateGoryDetail(row) {
      memberCoinDetail({
        auxiliaryId: row.auxiliaryId,
        totalOrderId: row.mainOrderId
      }).then(res => {
        // res为单个对象
        this.coinData = [res];
        this.coinVisible = true;
      });
    }
  }
};
</script>

<style scoped lang="less">
/deep/.ant-table-pagination.ant-pagination {
  margin: 0;
}
</style>
