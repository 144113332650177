<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      center
      :width="width"
      :footer="null"
      @cancel="close"
    >
      <div class="flex-start" v-if="showSearch">
        <p class="pb-1x">
          <a-input-search
            allow-clear
            class="search-input"
            :placeholder="placeholder"
            v-model="searchKey"
            @search="onSearch"
          >
            <a-button slot="enterButton" icon="search" type="primary" ghost>
              搜索
            </a-button>
          </a-input-search>
        </p>
      </div>
      <a-table
        :data-source="tableData"
        row-key="id"
        :scroll="{ x: 1000, y: 800 }"
        :pagination="pagination"
        @change="paginationChange"
      >
        <a-table-column
          v-for="column in tableColumns"
          :key="column.key"
          :width="column.width"
          :align="column.align ? column.align : ''"
          :ellipsis="column.ellipsis ? column.ellipsis : false"
          :fixed="column.fixed ? column.fixed : null"
        >
          <!--        头部显示-->
          <div slot="title">
            {{ column.title }}
          </div>
          <!--        body显示-->
          <!--        时间显示区域-->
          <span
            :class="column.primary ? column.primary : ''"
            v-if="
              column.scopedSlots && column.scopedSlots.customRender === 'date'
            "
            slot-scope="record"
          >
            {{
              record[column.key]
                | date(
                  column.scopedSlots.valueFormat
                    ? column.scopedSlots.valueFormat
                    : "YYYY-MM-DD hh:mm"
                )
            }}
          </span>
          <!--        金钱显示区域-->
          <span
            :class="column.primary ? column.primary : ''"
            v-else-if="
              column.scopedSlots && column.scopedSlots.customRender === 'money'
            "
            slot-scope="record"
          >
            {{ record[column.key] | money(column.scopedSlots.typeValue) }}
          </span>
          <!--        默认显示区域-->
          <span
            :title="column.ellipsis ? record[column.key] : ''"
            v-else
            slot-scope="record"
          >
            {{ record[column.key] }}
          </span>
        </a-table-column>
        <slot name="action"></slot>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "tablePopup",
  props: {
    showSearch: {
      type: Boolean,
      default() {
        return true;
      }
    },
    width: {
      type: String,
      default() {
        return "90%";
      }
    },
    placeholder: {
      type: String,
      default() {
        return "请输入单号";
      }
    },
    title: {
      type: String,
      default() {
        return "";
      }
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      }
    },
    tableColumns: {
      type: Array,
      default() {
        return [];
      }
    },
    tableData: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: [Object, Boolean],
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      searchKey: "" // 搜索key
    };
  },
  methods: {
    close() {
      this.searchKey = "";
      this.$emit("update:visible", false);
    },
    paginationChange(val) {
      this.$emit("paginationChange", val);
    },
    // 搜索
    onSearch() {
      this.$emit("onSearch", this.searchKey);
    }
  }
};
</script>

<style scoped lang="less">
/deep/.ant-modal-body {
  padding: 12px 24px 24px 24px;
}
</style>
