<template>
  <div>
    <h2 class="title-mid ">会员资产</h2>
    <a-row :gutter="[16, 8]" type="flex" justify="space-between">
      <a-col :xl="6" :lg="12">
        <div class="height-100 flex-col flex-between member-item img-1">
          <h2 class="pb-2x font-xxm">
            {{ memberInfoOne.currentLevelName || "临时会员" }}
          </h2>
          <div>
            <p v-if="memberInfoOne.whetherTemporaryMember === 0">
              <span>有效期: </span>
              <span v-if="memberInfoOne.privilegeEndDate">
                {{ memberInfoOne.privilegeStartDate | date }} ~
                {{ memberInfoOne.privilegeEndDate | date }}
              </span>
              <span v-else>永久有效</span>
            </p>
            <p v-else>有效期: {{ memberInfoOne.endValidTime | date }}</p>
            <div class="flex-between pt-1x">
              <div>
                消费折扣:
                <span>
                  <template v-if="memberInfoOne.counsumeDiscount === 1">
                    无折扣
                  </template>
                  <template v-else-if="memberInfoOne.memberLevelRate"
                    >{{ memberInfoOne.memberLevelRate }}折</template
                  >
                  <template v-else>-</template>
                </span>
              </div>
              <p @click="memberUpgradeClick">
                <span class="red pr-x pointer">开通会员特权</span>
                <a-icon type="right" />
              </p>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xl="6" :lg="12">
        <div class="height-100 member-item flex-col flex-between  img-2">
          <div>
            <h2 class="font-l bold">会员可用余额</h2>
            <h2 class="font-xxxxl">
              {{ memberInfoOne.buyBalance || 0 | money }}
            </h2>
          </div>
          <div class="mt-x">
            <a-button
              type="primary"
              shape="round"
              size="small"
              class="mr-1x"
              @click="toRecharge"
              >充值</a-button
            >
            <a-button
              shape="round"
              size="small"
              class="mr-1x mt-x"
              @click="particularsClick"
              >明细</a-button
            >
            <a-button
              shape="round"
              size="small"
              class="mt-x"
              @click="refundClick"
              >退款</a-button
            >
          </div>
        </div>
      </a-col>
      <a-col :xl="6" :lg="12">
        <div class="height-100 member-item flex-col flex-between img-3">
          <h2 class="font-l bold pb-1x">积分</h2>
          <div>
            <p class="pb-1x">
              最多可抵扣:
              <span class="gray">{{
                memberInfoOne.deductionMoney | money
              }}</span>
            </p>
            <p class="pb-1x">
              会员可用积分:
              <span class="gray">{{
                memberInfoOne.usableIntegral || "-"
              }}</span>
            </p>
            <div class="flex-between">
              <p>
                本月将到期积分:
                <span class="gray">{{
                  memberInfoOne.monthlyExpireIntegral || "-"
                }}</span>
              </p>
              <a-button shape="round" size="small" @click="integralDetail"
                >明细</a-button
              >
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xl="6" :lg="12">
        <div class="height-100 flex-col flex-between member-item img-3">
          <div>
            <h2 class="font-l bold">成长值</h2>
            <h2 class="font-xxxxl">
              {{
                memberInfoOne.totalGrowth === 0 || memberInfoOne.totalGrowth
                  ? memberInfoOne.totalGrowth
                  : "-"
              }}
            </h2>
          </div>
          <div class="flex-between">
            <p class="font-s mt-x" v-if="memberInfoOne.needGrowth">
              *再获取
              <span class="red">{{ memberInfoOne.needGrowth || "-" }}</span
              >个成长值可升级至
              <span>{{ memberInfoOne.nextLevel }}</span> 级会员
            </p>
            <a-button shape="round" size="small" @click="growDetail"
              >明细</a-button
            >
          </div>
        </div>
      </a-col>
    </a-row>
    <!--    积分明细列表，成长值明细列表公用-->
    <TablePopup
      :title="tableTitle"
      placeholder="请输入订单号"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :visible.sync="integralVisible"
      :pagination="tablePagination"
      @paginationChange="integralPaginationChange"
      @onSearch="integralSearch"
    />
    <!-- 会员余额明细 -->
    <a-modal
      title="明细"
      :visible="particularsShow"
      center
      width="80%"
      :footer="null"
      @cancel="particularsShow = false"
    >
      <a-tabs
        default-active-key="1"
        type="card"
        tabPosition="top"
        v-model="activeIndex"
      >
        <a-tab-pane key="1" tab="可用余额明细">
          <a-table
            :columns="particularsColumns"
            :data-source="particularsData"
            :pagination="false"
            row-key="id"
            :scroll="{ x: 500, y: 500 }"
          >
            <template slot="remainingSum" slot-scope="row">
              {{ row.remainingSum | money }}
            </template>
            <template slot="assetValidTime" slot-scope="row">
              {{ row.assetValidTime | date }}
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="资产变更明细">
          <div class="flex-start">
            <p class="pb-1x">
              <a-input-search
                allow-clear
                class="search-input"
                placeholder="请输入订单号"
                v-model="alterationKey"
                @search="getBalanceRecordList"
              >
                <a-button slot="enterButton" icon="search" type="primary" ghost>
                  搜索
                </a-button>
              </a-input-search>
            </p>
          </div>
          <a-table
            :scroll="{ x: 1300, y: 430 }"
            class="flex-grow"
            :columns="alterationColumns"
            :data-source="alterationData"
            :pagination="alterationPagination"
            @change="alterationChange"
            :row-key="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="createTime" slot-scope="row">
              {{ row.createTime | date("YYYY-MM-DD hh:mm:ss") }}
            </template>
            <template slot="changeNum" slot-scope="row">
              {{ row.changeNum | money }}
            </template>
            <template slot="changeTotal" slot-scope="row">
              {{ row.changeTotal | money }}
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import {
  integralRecord,
  growthRecord,
  findMemberProperty,
  balanceRecord
} from "@/api/member"; // MS-会员中心接口
import TablePopup from "@/components/member/TablePopup";
import { mapGetters } from "vuex";
// 成长值
const growUpColumns = [
  {
    key: "createTime",
    title: "变更时间",
    fixed: "left",
    width: 180,
    scopedSlots: { customRender: "date" }
  },
  {
    key: "totalOrderId",
    title: "总单号",
    width: 190
  },
  {
    key: "changeTypeText",
    title: "变动类型",
    width: 160
  },
  {
    key: "memberBusinessTypeText",
    title: "业务类型",
    width: 150
  },
  {
    key: "changeNum",
    title: "变动成长值",
    width: 110
  },
  {
    key: "changeTotal",
    title: "变动后成长值",
    width: 120
  },
  {
    key: "tradingTypeText",
    title: "交易渠道",
    width: 100
  },
  {
    key: "creatorName",
    title: "操作人",
    width: 100
  },
  {
    key: "remark",
    title: "支付备注",
    width: 150,
    ellipsis: true
  }
];
// 积分明细
const integralColumns = [
  {
    key: "createTime",
    title: "变更时间",
    fixed: "left",
    width: 180,
    scopedSlots: { customRender: "date" }
  },
  {
    key: "totalOrderId",
    title: "总单号",
    width: 190
  },
  {
    key: "changeNum",
    title: "变更数量",
    width: 100
  },
  {
    key: "changeTotal",
    title: "变更后数量",
    width: 100
  },
  {
    key: "tradingTypeText",
    title: "交易渠道",
    width: 100
  },
  {
    key: "creatorName",
    title: "操作人",
    width: 100
  },
  {
    key: "remark",
    title: "变更备注",
    width: 150,
    ellipsis: true
  }
];
// 余额明细
const particularsColumns = [
  {
    title: "资产名称",
    key: "productName",
    dataIndex: "productName"
  },
  {
    title: "金额",
    key: "remainingSum",
    scopedSlots: {
      customRender: "remainingSum"
    }
  },
  {
    title: "有效期",
    key: "assetValidTime",
    scopedSlots: {
      customRender: "assetValidTime"
    }
  },
  {
    title: "可用产品",
    key: "applicationNameArr",
    dataIndex: "applicationNameArr",
    ellipsis: true
  }
];
// 余额变更明细
const alterationColumns = [
  {
    title: "变更时间",
    scopedSlots: { customRender: "createTime" },
    width: 170
  },
  {
    title: "资产名称",
    dataIndex: "productName",
    width: 170
  },
  { title: "单据编号", dataIndex: "totalOrderId", width: 190 },
  { title: "业务类型", dataIndex: "memberBusinessTypeText", width: 150 },
  { title: "交易商户", dataIndex: "merchantName", width: 200 },
  { title: "交易渠道", dataIndex: "tradingTypeText", width: 100 },
  { title: "变更账户", dataIndex: "belongAccountText", width: 100 },
  { title: "变更金额", scopedSlots: { customRender: "changeNum" }, width: 120 },
  {
    title: "变更后余额",
    scopedSlots: { customRender: "changeTotal" },
    width: 120
  },
  { title: "操作人", dataIndex: "creatorName", width: 120 },
  { title: "支付备注", dataIndex: "remark", width: 150, ellipsis: true }
];
export default {
  name: "alterationMemberPage",
  props: {
    memberInfoOne: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    TablePopup
  },
  data() {
    return {
      particularsColumns, // 余额明细弹框table的columns配置
      particularsShow: false, // 资产明细弹框
      activeIndex: "1",
      searchKey: "", // 搜索关键词
      popupType: "", // 弹框类型
      tableColumns: [],
      particularsData: [], // 余额明细弹框数据
      integralVisible: false, // 积分明细弹框
      tableTitle: "",
      tablePagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      tableData: [],
      alterationColumns, // 变更余额明细
      alterationData: [], // 变更余额明细列表
      alterationKey: "", // 订单号
      // 分页参数
      alterationPagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showSizeChanger: true,
        showQuickJumper: true
      }
    };
  },
  computed: {
    ...mapGetters({ memberId: "common/memberId" })
  },
  methods: {
    // 会员可用余额明细点击事件
    particularsClick() {
      this.particularsShow = true;
      findMemberProperty({
        memberId: this.memberId
      }).then(res => {
        res.forEach(item => {
          let applicationNameArr = [];
          if (item.applicableProducts) {
            item.applicableProducts.forEach(temp => {
              applicationNameArr.push(temp.productName);
            });
            item.applicationNameArr = applicationNameArr.join(",");
          }
        });
        this.particularsData = res || [];
      });
      // 加载资产明细
      this.alterationPagination.current = 1;
      this.alterationKey = "";
      this.getBalanceRecordList();
    },
    // 会员退款点击事件
    refundClick() {
      this.$router.push({
        path: "/home/member/MemberRefundBalanceList"
      });
    },
    memberUpgradeClick() {
      this.$router.push({ path: "/home/member/MemberUpgrade" });
    },
    growDetail() {
      this.resetTable();
      this.popupType = 2; //  成长值变更弹框
      this.tableTitle = "成长值";
      this.tableColumns = growUpColumns; // 成长值columns
      this.integralRecordLoad();
    },
    integralDetail() {
      this.resetTable();
      this.popupType = 1; // 积分弹框
      this.tableTitle = "积分明细";
      this.tableColumns = integralColumns;
      this.integralRecordLoad();
    },
    // 积分明细，成长值
    integralRecordLoad() {
      this.integralVisible = true;
      if (this.popupType === 1) {
        // 积分明细
        integralRecord({
          id: this.memberId,
          totalOrderId: this.searchKey,
          page: this.tablePagination.current,
          size: this.tablePagination.pageSize
        }).then(res => {
          this.tableData = res.records;
          this.tablePagination.total = res.total;
        });
      } else if (this.popupType === 2) {
        // 成长值明细
        growthRecord({
          id: this.memberId,
          totalOrderId: this.searchKey,
          page: this.tablePagination.current,
          size: this.tablePagination.pageSize
        }).then(res => {
          this.tableData = res.records;
          this.tablePagination.total = res.total;
        });
      }
    },
    integralSearch(val) {
      // 积分弹框搜索
      this.searchKey = val;
      this.tablePagination.current = 1;
      this.integralRecordLoad();
    },
    integralPaginationChange(val) {
      // 积分弹框页数改变触发
      this.tablePagination.current = val.current;
      this.tablePagination.pageSize = val.pageSize;
      this.integralRecordLoad();
    },
    // 去充值
    toRecharge() {
      this.$router.push({ path: "/home/member/MemberRecharge" });
    },
    // 分页变化时触发
    alterationChange(val) {
      this.alterationPagination.current = val.current;
      this.alterationPagination.pageSize = val.pageSize;
      this.getBalanceRecordList();
    },
    // 查询资产变更记录
    getBalanceRecordList() {
      let obj = {
        page: this.alterationPagination.current,
        size: this.alterationPagination.pageSize,
        id: this.memberId,
        totalOrderId: this.alterationKey
      };
      balanceRecord(obj)
        .then(res => {
          this.alterationData = res.records;
          this.alterationPagination.total = res.total;
        })
        .catch(() => {});
    },
    // 弹框表单重置
    resetTable() {
      this.tablePagination = {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showSizeChanger: true,
        showQuickJumper: true
      };
      this.searchKey = "";
      this.tableData = [];
    }
  }
};
</script>
@import "../../../../assets/less/custom.less";
<style scoped lang="less">
.height-100 {
  height: 100%;
}
.member-item {
  flex-shrink: 0;
  overflow: hidden;
  /*width: 388px;*/
  border-radius: 16px;
  padding: 16px 16px !important;
  /*box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);*/
  &.img-1 {
    background-image: url("../../../../assets/images/menber-img1.png");
    background-size: cover;
  }
  &.img-2 {
    background: #eff5ff;
  }
  &.img-3 {
    background: #f6f6f6;
  }
}
</style>
