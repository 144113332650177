<template>
  <div class="title-wrapper flex-between bg-white shadow">
    <slot>
      <span class="font-xl">{{ title }}</span>
    </slot>
    <a-icon
      class="font-xxl pointer gray"
      type="close"
      v-if="close"
      @click="closePage"
    />
  </div>
</template>

<script>
export default {
  name: "PanelHead",
  props: {
    // 标题
    title: {
      type: String,
      default: ""
    },
    // 关闭按钮
    close: {
      type: Boolean,
      default: true
    },
    stop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullPath: "", //路由地址
      orderType: "" //路径
    };
  },
  created() {
    this.fullPath = this.$route.query.fullPath;
    this.orderType = this.$route.query.orderType;
  },
  methods: {
    closePage() {
      if (this.$listeners.close) {
        this.$emit("close");
      } else {
        //订单列表到详情页，记录下路由地址及订单类型
        if (this.fullPath) {
          this.$router.push({
            path: this.fullPath,
            query: {
              orderType: this.orderType
            }
          });
        } else {
          this.$router.go(-1);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

.title-wrapper {
  padding: 16px;
  align-items: center;
  line-height: 24px;
  z-index: 1;
}
</style>
